/* src/components/banner/Banner.scss */
.banner-container {
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 16px;
}

.banner-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.header-left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.header-icon {
  font-size: 40px;
  color: #3f51b5; /* Adjust color as needed */
}

.header-info {
  display: flex;
  flex-direction: column;
}

.header-title {
  font-size: 24px;
  font-weight: 600;
}

.header-subtitle {
  font-size: 14px;
  color: #666;
}

.header-right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.banner-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.banner-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.banner-divider {
  height: 24px;
}

@media (max-width: 768px) {
  .banner-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-right {
    width: 100%;
    justify-content: flex-start;
  }

  .banner-divider {
    display: none;
  }
}
.layout-container {
    display: flex;
    height: 100vh;
  
    .left-menu {
      width: 250px;
      border-right: 1px solid #e0e0e0;
      padding: 16px;
      box-sizing: border-box;
  
      .profile-summary {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        margin-bottom: 16px;
  
        .profile-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #e0e0e0;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
        }
  
        .profile-name {
          font-size: 1.2rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 4px;
        }
  
        .profile-info {
          font-size: 0.9rem;
          color: #666;
          text-align: center;
        }
      }
    }
  
    .main-content {
      flex: 1;
      padding: 16px;
      box-sizing: border-box;
      // overflow-y: auto;
    }
  }
  
.patient-page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.modal-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

@media (max-width: 600px) {
  .modal-container {
    padding: 1rem;
  }
}
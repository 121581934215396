.app-bar {
    background-color: #212121;
    padding: 0 16px;
    height: 48px; /* Decrease the height */
  }
  
  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 48px; /* Ensure the toolbar matches the app bar height */
    padding: 0;
  }
  
  .title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 16px;
    font-size: 1rem; /* Adjust font size to fit within the reduced height */
    white-space: nowrap; /* Prevent text wrapping */
  
    .logo {
      font-weight: bold;
      margin-right: 8px;
      font-size: 1.25rem; /* Adjust font size to fit within the reduced height */
    }
  }
  
  .nav-links {
    display: flex;
    align-items: center;
    gap: 8px; /* Decrease gap between menu items */
  
    .MuiButton-root {
      font-size: 0.75rem; /* Reduce font size */
      padding: 4px 8px; /* Adjust padding to fit within the reduced height */
      color: #ffffff;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  
    @media (max-width: 768px) { /* Adjust breakpoint as needed */
      display: none; /* Hide nav links on smaller screens */
    }
  }
  
  .menu-button {
    display: none; /* Hide menu button on larger screens */
  
    @media (max-width: 768px) {
      display: block; /* Show menu button on smaller screens */
    }
  }
  
  .profile-menu {
    display: flex;
    align-items: center;
  
    .MuiIconButton-root {
      padding: 4px;
      margin-right: 8px;
    }
  
    .user-name {
      font-size: 0.75rem; /* Adjust font size to fit within the reduced height */
      color: #ffffff;
    }
  }
  
  .drawer-content {
    width: 250px;
    padding: 16px;
  
    .drawer-close-btn {
      display: flex;
      justify-content: flex-end;
    }
  
    .MuiList-root {
      margin-top: 16px;
  
      .MuiListItem-root {
        padding: 8px 16px;
      }
    }
  }
  
.patient-docs-sample-page {
  padding: 24px;

  .mode-toggle {
    margin-top: 32px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  .category-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;

    .category-item {
      flex: 1 1 150px; // Grow, shrink, and basis
      max-width: 200px; // Maximum width
      min-width: 120px; // Minimum width
    }

    .category-paper {
      padding: 12px;
      text-align: center;
      cursor: pointer;
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:hover {
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
      }

      .category-text {
        word-break: break-word;
        font-size: 0.9rem;
      }
    }
  }

  .upload-section {
    margin-top: 32px;
    padding: 16px;
    border-radius: 4px;
  }
}

// Media queries for responsiveness
@media (max-width: 600px) {
  .patient-docs-sample-page {
    .mode-toggle {
      flex-direction: column;
      align-items: flex-start;
    }

    .category-grid {
      .category-item {
        // flex-basis: 100%; // Full width on very small screens
        flex-basis: calc(50% - 6px); // Two items per row on very small screens
      }
    }
  }
}
.all-patients-page {
    .patients-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
  
      .patient-item {
        display: flex;
        align-items: center;
        padding: 16px;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
  
        .patient-avatar {
          width: 60px;
          height: 60px;
          margin-right: 16px;
        }
  
        .patient-info {
          display: flex;
          flex-direction: column;
  
          .MuiTypography-h6 {
            font-weight: bold;
          }
        }
      }
    }
  }
  
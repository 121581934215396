.patient-nav {
    .patient-nav-item {
      padding-top: 4px;
      padding-bottom: 4px;
  
      .list-item-icon {
        min-width: 32px; /* Adjust the min-width to decrease the spacing */
      }
    }
  
    .custom-divider {
      margin: 8px 0; /* Add some vertical spacing around the divider */
    }
  }
  
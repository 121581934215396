.assigned-form-results {
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  
    .row {
      margin-bottom: 10px;
      padding: 10px;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    }
  
    .question {
      font-weight: bold;
      margin-right: 10px;
      color: #333;
    }
  
    .answer {
      color: #666;
    }
  }
.general-assessment-form-container {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the container takes full height */
    overflow: hidden; /* Prevent unnecessary scrollbars */
  }
  
  .form-content {
    flex-grow: 1; /* Allow form content to expand to fill remaining space */
    overflow-y: auto; /* Enable vertical scrollbar when needed */
  }
  
.patient-details-container {
  display: flex;
  padding: 16px;

  .patient-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the top */
    margin-bottom: 16px;
    padding: 16px;
    background-color: #f5f5f5;
    border-radius: 8px;

    .header-left {
      display: flex;
      align-items: flex-start; /* Align items to the top */

      .header-icon {
        margin-right: 8px;
        font-size: 2rem;
        align-self: flex-start; /* Align self to the top */
      }

      .header-info {
        display: flex;
        flex-direction: column;

        .header-title {
          font-size: 1.5rem;
          font-weight: bold;
        }

        .header-subtitle {
          font-size: 0.9rem;
          color: #666;
        }
      }
    }

    .patient-actions {
      display: flex;

      button {
        margin-left: 8px;
      }
    }
  }

  .left-menu {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid #e0e0e0;

    .profile-summary {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
      margin-bottom: 16px;

      .profile-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
      }

      .profile-name {
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 4px;
      }

      .profile-info {
        font-size: 0.9rem;
        color: #666;
        text-align: center;
      }
    }
  }

  .main-panel {
    flex: 1;
  }
}

.dashboard-page {
    padding: 16px;
  }
  
  .dashboard-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2px; /* Minimal space between cards */
    justify-content: space-between;
  }
  
  .dashboard-card-link {
    flex: 1 0 21%; /* Ensure four cards per row before wrapping */
    margin: 2px; /* Minimal spacing between cards */
    text-decoration: none; /* Remove underline from links */
    box-sizing: border-box; /* Ensure padding and border are included in the width and height */
  }
  
  .dashboard-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 150px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
  }
  
  .card-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 8px;
  }
  
  .card-header svg {
    font-size: 20px; /* Adjust icon size if needed */
  }
  
  .card-count {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 1.25rem; /* Decrease font size */
  }
  
  .card-title {
    font-size: 1rem; /* Adjust title font size */
  }
  
  .card-description {
    font-size: 0.875rem; /* Adjust description font size */
  }
  
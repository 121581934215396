
.sign-in-page {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .sign-in-page h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sign-in-page form div {
    margin-bottom: 15px;
  }
  
  .sign-in-page label {
    display: block;
    margin-bottom: 5px;
  }
  
  .sign-in-page input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .sign-in-page .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .sign-in-page button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .sign-in-page button:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .sign-in-page p {
    text-align: center;
    margin-top: 15px;
  }
  
  .sign-in-page a {
    color: #007bff;
    text-decoration: none;
  }
  
  .sign-in-page a:hover {
    text-decoration: underline;
  }
  
  /* auth.css */

/* Common Styles for Authentication Pages */
.auth-page {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.auth-page h2 {
  text-align: center;
  margin-bottom: 20px;
}

.auth-page form div {
  margin-bottom: 15px;
}

.auth-page label {
  display: block;
  margin-bottom: 5px;
}

.auth-page input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.auth-page .error {
  color: red;
  margin-bottom: 10px;
}

.auth-page .success {
  color: green;
  margin-bottom: 10px;
}

.auth-page button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.auth-page button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.auth-page p {
  text-align: center;
  margin-top: 15px;
}

.auth-page a {
  color: #007bff;
  text-decoration: none;
}

.auth-page a:hover {
  text-decoration: underline;
}

/* Specific Styles for Reset Password Page (if needed) */
.reset-password .diagnostics {
  background-color: #f1f1f1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}
